<template>
  <div id="subpage-content">
    <CompanyDrawer />
    <div id="rcol">
      <v-card id="rcol-incard">
        <h1 id="naming">{{ $t("message.FIO1") }}</h1>
        <div id="about-text-work">
          <img
            width="220"
            height="250"
            id="workpic"
            :src="getImageUrlbyName('Dir')"
          />
          <span id="pad" v-html="$t('message.LadaANText1')"></span>
          <span id="pad" v-html="$t('message.LadaANText2')"></span>
          <span id="nopad" v-html="$t('message.LadaANText3')"></span>
          <span id="pad" v-html="$t('message.LadaANText4')"></span>
          <span id="pad" v-html="$t('message.LadaANText5')"></span>
          <span id="pad" v-html="$t('message.LadaANText6')"></span>
          <span id="pad" v-html="$t('message.LadaANText7')"></span>
          <span id="nopad" v-html="$t('message.LadaANText8')"></span>
          <span id="pad" v-html="$t('message.SocialNet')"></span>
          <div id="pad">
            <span v-html="$t('message.VK')"></span>
            <a href="http://vk.com/id46169035">http://vk.com/id46169035</a>
          </div>
          <div id="pad">
            <span v-html="$t('message.FB')"></span>
            <a href="http://www.facebook.com/alexander.ladalexer"
              >http://www.facebook.com/alexander.ladalexer</a
            >
          </div>
          <div id="pad">
            <span v-html="$t('message.Insta')"></span>
            <a href="https://www.instagram.com/ladalexer"
              >https://www.instagram.com/ladalexer</a
            >
          </div>
          <div id="pad">
            <v-icon small>mdi-email</v-icon>
            <a id="pad-left" href="mailto:lada@kg.ru">lada@kg.ru</a>
          </div>
          <div id="pad">
            <v-icon small>mdi-tag</v-icon>
            <span id="pad-left">{{ $t("message.JobTitleDir") }}</span>
          </div>
          <div id="pad">
            <v-icon small>mdi-phone</v-icon>
            <span id="pad-left">+7 (846) 219-26-28</span>
          </div>
          <a id="backto" @click="$router.push('/company/workers')">
            <v-icon large>mdi-arrow-left-bold-circle</v-icon>
            <a id="pad-left">{{ $t("message.WorkList") }}</a>
          </a>
        </div>
      </v-card>
    </div>
  </div>
</template>

<style>
#about-text-work {
  display: flex;
  flex-direction: column;
  font: 13px/20px Ubuntu, Arial, sans-serif;
}
</style>

<script>
import CompanyDrawer from "../../../../views/Navigations/Company/CompanyDrawer";
export default {
  components: {
    CompanyDrawer,
  },
};
</script>
